@import './assets/sass/theme.scss';

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main {
  height: calc(100% - 112px);
}

.tags {
  max-height: 100%;
  overflow: auto;
}

.app {
  &-shell {
      display: flex;
      flex-direction: column;
      height: 100vh;
  }

  &-main {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      overflow: auto;
  }

  &-navbar, &-sidebar {
      .ms-Icon {
        color: #ccc;
        &:hover {
            color: #fff;
        }
    }
  }

  &-navbar {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 40px;
      line-height: 40px;


      ul, li {
          margin: 0;
          padding: 0;
      }

      li {
          list-style-type: none;

          a {
              display: inline-block;
              padding: 0 10px;
              max-height: 40px;

              &.active, &:hover {
                .ms-Icon {
                    color: #fff;
                }
                background-color: #666;
              }
          }
      }

      &-brand {
          flex-grow: 1;
          padding: 0 10px;
      }
  }

  &-sidebar {
      font-size: 20px;
      display: flex;
      flex-direction: column;

      &-fill {
          flex-grow: 1;
      }

      ul {
          padding: 0;
          margin: 0;
      }

      li {
          display: block;
          text-align: center;

          a, span {
              display: inline-block;
              width: 45px;
              padding: 5px 10px;

              &.active, &:hover {
                .ms-Icon {
                    color: #fff;
                }
                background-color: $lighter-3;
              }

              &.disabled {
                  opacity: 0.25;

                  &:hover {
                      background-color: transparent;
                  }

                  &.ms-Icon {
                    color: transparent;
                  }
              }
          }
      }
  }

  &-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
  }
}

.cursor-pointer {
    cursor: pointer
}

.cusror-text {
    cursor: text
}

//header {
//    padding-bottom: 5px;
//}
