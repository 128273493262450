@import '../assets/sass/theme.scss';

.map-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    padding-bottom: 0px;
  }

  .map {
    background-color: Gainsboro;
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }

.toolbar-btn {
  margin: 5px;
  width: 48px;
  height: 48px;
  outline: none;
  background-color: $darker-1;
  border: solid 1px $lighter-2;
  color: rgb(0, 161, 241);
  z-index: 10;

  &:hover, &.active {
    background-color: $darker-2;
    border: solid 1px $lighter-3;
  }

  &:active {
      background-color: $darker-2;
  }
  .ms-Icon {
      font-weight: 600;
  }
}

.prev {
  position: absolute;
  top: 50%;
  left: 50px;
  margin-left: 10px;
}

.next {
  position: absolute;
  top: 50%;
  right: 50px;
  margin-right: 10px;
}

.page-number {
  position: absolute;
  width: 200px;
  bottom: 0;
  left: 50%;
  margin-left: -100px;
  color: white;
  text-shadow:
     1px  1px 0 #000,
    -1px -1px 0 #000,
     1px -1px 0 #000,
    -1px  1px 0 #000,
     1px  1px 0 #000;
  text-align: center;
  font-size: 18px;
  font-family: fantasy;
}

.canvas-ocr-loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  display: flex;
  z-index: 11;
}

.canvas-ocr-loading-spinner {
  margin:auto;
  font-size: 24px;
}

.tooltip-container {
  width: 100%;
  height: 100%;
}

.additional-action-dropdown {
  margin-left: 0.5rem;
  margin-right: -1rem;
}

.disabled {
    opacity: 0.3;
}

.ol-dragbox-style {
  border: dashed;
  border-width: 1px ;
  border-color: black;
}
